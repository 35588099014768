<template>
  <div class="config">
    <BaseMessage
        v-if="hasErrors"
        v-model="hasErrors"
        :title="translate('error', 'Error')"
        :description="errMsg"
        :confirmLabel="translate('okay', 'Okay')"
        :hasCancel="false"
        :is-idle="false"
        @on-reset="onOkay"
    >
    </BaseMessage>
    <section class="config__container d-flex flex-column justify-center align-center pa-5" ref="form">
      <img style="max-width: 480px; width: 100%" src="@/assets/img/config/solo.kiosk.svg" alt="">
      <v-select
          class="config-input"
          ref="concept"
          :items="getUserConcepts"
          v-model="concept"
          label="Select a concept"
          outlined
          dense
          :item-text="item => item.label"
          :item-value="item => item"
          :rules="[rules.required]"
          @change="onChangeConcept"
          required
      ></v-select>
      <v-select
          class="config-input"
          ref="branch"
          :items="branches"
          v-model="branch"
          label="Select a branch"
          outlined
          dense
          :item-text="item => item.attributes.name"
          :item-value="item => item"
          :rules="[rules.required]"
          required
      ></v-select>
      <v-select
          class="config-input"
          ref="terminal"
          :items="terminals"
          v-model="terminal"
          label="Select a payment terminal"
          outlined
          dense
          :item-text="item => item"
          :item-value="item => item"
          :rules="[rules.required]"
          required
      ></v-select>
      <v-text-field
          class="config-input"
          ref="terminalId"
          v-model="terminalId"
          outlined
          label="Terminal ID"
          :rules="[rules.required]"
          @focus="onFocus('terminalId')"
          @blur="onBlur()"
          required
      ></v-text-field>
      <v-text-field
          class="config-input"
          ref="ipAddress"
          v-model="ipAddress"
          outlined
          label="IP Address"
          :rules="[rules.required]"
          @focus="onFocus('ipAddress')"
          @blur="onBlur()"
          required
      ></v-text-field>
      <v-btn
        elevation="2"
        class="update-btn mb-8"
        depressed
        style="background: #fff"
        @click="onUpdate"
        x-large
      >
        <v-progress-circular
          v-if="this.isLoading.update"
          class="mr-3"
          :size="20"
          color="primary"
          indeterminate
        ></v-progress-circular> Update
      </v-btn>
      <v-btn
          elevation="2"
          class="update-btn"
          depressed
          style="background: #fff"
          @click="onLogout"
          x-large
      >
        <v-progress-circular
            v-if="this.isLoading.logout"
            class="mr-3"
            :size="20"
            color="primary"
            indeterminate
        ></v-progress-circular> Logout
      </v-btn>
      <div v-show="input" class="simple-keyboard"></div>
    </section>
  </div>
</template>
<style lang="scss">
.config, .config__container {
  height: 100%;
  .v-input {
    display: block;
    flex: none;
  }
  .v-input__slot {
    height: 56px;
  }
  h1 {
    font-size: 32px;
  }
  .config-input, .update-btn {
    max-width: 480px;
    width: 100%;
  }
}
</style>
<script>
import { mapGetters, mapMutations } from 'vuex';
import Keyboard from 'simple-keyboard';
import { Category, Concept, Location } from '../../services/SOLO';
import translationMixin from '../../mixins/translations';
import 'simple-keyboard/build/css/index.css';

export default {
  name: 'ConfigForm',
  mixins: [translationMixin],
  computed: {
    ...mapGetters({
      getApplications: 'concept/getApplications',
      getConcept: 'concept/getConcept',
      getUserConcepts: 'config/getUserConcepts',
      getSelectedConcept: 'config/getSelectedConcept',
      getConfiguration: 'config/getConfiguration',
    }),
    form() {
      return {
        concept: this.concept,
        branch: this.branch,
        terminal: this.terminal,
        terminalId: this.terminalId,
        ipAddress: this.ipAddress,
      };
    },
  },
  created() {
    this.concept = this.getSelectedConcept;
    this.terminal = this.getConfiguration.terminal;
    this.terminalId = this.getConfiguration.terminalId;
    this.ipAddress = this.getConfiguration.ipAddress;
    this.onChangeConcept(this.concept);
  },
  mounted() {
    this.keyboard = new Keyboard('simple-keyboard', {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
    });
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'This field is required.',
      },
      terminals: [
        'Ingenico',
        'Geidea',
      ],
      concept: null,
      apiConcept: null,
      branch: null,
      terminal: null,
      terminalId: null,
      ipAddress: null,
      formHasErrors: false,
      branches: [],
      applications: [],
      isLoading: {
        update: false,
        logout: false,
      },
      hasErrors: false,
      errMsg: '',
      keyboard: null,
      input: null,
      blockBlur: false,
    };
  },
  methods: {
    ...mapMutations({
      setConfiguration: 'config/setConfiguration',
      setIsConfigured: 'config/setIsConfigured',
      setSelectedConcept: 'config/setSelectedConcept',
      setApplications: 'concept/setApplications',
      setConcept: 'concept/setConcept',
      setMenu: 'cart/setMenu',
      clearConfiguration: 'config/clearConfiguration',
    }),
    loadLocations(conceptId) {
      Location.fetch(conceptId)
        .then((response) => {
          this.branches = response.data.data;
          this.branch = this.getConfiguration.branch || this.branches[0];
          console.log('this.branch: ', this.branch);
          this.$forceUpdate();
        });
    },
    onChangeConcept(concept) {
      console.log('concept: ', concept);
      this.branches = [];
      this.branch = null;
      Concept.get(concept.key)
        .then((response) => {
          this.apiConcept = response.data.data;
        })
      this.loadLocations(concept.key);
    },
    getAppMenu() {
      return Category.cdn(this.getApplications['menu-ref']);
    },
    handleError(err) {
      console.log('handleError - err: ', err.response);
      this.hasErrors = true;
      err?.response?.data?.error?.forEach((e) => {
        this.errMsg += `<p style="text-transform: capitalize">${e?.detail}</p>`
      });
      if (!this.errMsg) {
        if (err?.response?.data?.error) {
          this.errMsg = err?.response?.data?.error.detail;
        } else {
          this.errMsg = 'Something went wrong!';
        }
      }
    },
    onLogout() {
      this.isLoading.logout = true;
      this.clearConfiguration('hard');
      setTimeout(() => {
        this.$router.push({ name: 'config.login' });
        this.isLoading.logout = false;
      }, 1000);
    },
    onOkay() {
      this.errMsg = '';
      this.hasErrors = false;
    },
    onUpdate() {
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f]?.validate(true);
      });
      console.log('this.formHasErrors:', this.formHasErrors);
      if (!this.formHasErrors) {
        if (!this.isLoading.update) {
          this.isLoading.update = true;
          Concept.getApplication(this.concept.key)
            .then((response) => {
              const apps = response.data.data;
              let filtered = apps.filter((app) => app.attributes['application-type'] === 'kiosk');
              if (!filtered.length) {
                filtered = apps.filter((app) => app.attributes['application-type'] === 'social-ordering');
              }
              if (!filtered.length) {
                filtered = apps;
              }
              const app = filtered[0];
              this.setApplications({ ...app.attributes, ...{ id: app.id }});
              this.setConcept({ ...this.apiConcept.attributes, ...{ id: this.apiConcept.id }});
              return this.getAppMenu();
            })
            .then((response) => {
              this.setMenu(response.data);
              this.setTheme();
              this.setConfiguration(this.form);
              this.setIsConfigured(true);
              this.$router.push({ name: 'home' });
            })
            .catch(this.handleError)
            .then(() => (this.isLoading.update = false));
        }
      }
    },
    setTheme() {
      if (this.getApplications?.theme) {
        this.$vuetify.theme.themes.light.primary = this.getApplications.theme.primary_color;
        this.$vuetify.theme.themes.light.secondary = this.getApplications.theme.secondary_color;
        this.$vuetify.theme.themes.light.background = this.getApplications.theme.background_color || this.getApplications.theme.primary_color;
      }
    },
    onChange(input) {
      this[this.input] = input;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[this.input].focus();
        }, 200);
      });
    },
    onKeyPress(button) {
      this.blockBlur = true;
      if (button === '{shift}' || button === '{lock}'){
        this.handleShift();
      }
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    onFocus(inputType) {
      this.input = inputType;
      this.keyboard.setInput(this[this.input]);
    },
    onBlur() {
      console.log('this.blockBlur: ', this.blockBlur);
      if (this.blockBlur) {
        this.blockBlur = false;
        return;
      }
      this.input = null;
    },
  }
}
</script>
